export function moreMenuMain(){
    return [
        {
            title:"首页",
            link:"/index"
        },
        {
            title:"画廊",
            link:"/gallery"
        },
        {
            title:"艺术家",
            link:"/artist"
        },
        // {
        //     title:"我的空间",
        //     link:"/###"
        // },
    ]
}

export function moreMenuAbout(){
    return [
        {
            title:"我的",
            link:"/mine"
        },
        {
            title:"关于",
            link:"/about"
        },
        {
            title:"问题",
            link:"/question"
        },
        // {
        //     title:"快递",
        //     link:"/expressage"
        // },
        {
            title:"联系我们",
            link:"/relation"
        },
    ]
}

export function about(){
    return [
        {
            text:[
                "上殊房旨在打造一个艺术品的多维空间。",
                "借用动词“上”意指真正地行动起来。将一些大众熟知却又难以 触及的艺术品以更新颖、直观的方式呈现于大众眼前。而“殊” 则是特别。我们与艺术家的联动既有传统的联名产品又有新颖的 将艺术品由二维到三维的一个转换，以期让艺术爱好者有更好的 鉴赏艺术品的新方式。希望能吸引喜欢艺术的同行者，成为独有 的艺术空间，为大家带来更多的特别的惊喜内容。"
            ],
            id:"10000001"
        },
        {
            text:[
                "上殊房通过携手知名艺术家联袂推出各种潮流新颖的艺术衍生品 致力于传播中国文化、增强中国文化影响力，为中国的艺术产业 做实做强尽一份绵薄之力，为广大的艺术爱好者提供能体现中国 文化内核的艺术衍生品。"
            ],
            id:"10000002"
        },
    ]
}

export function relation(){
    return [
        "杭州寸间大造文化发展有限公司",
        "地址：浙江省杭州市上城区越秀维多利中心15A02",
        "联系电话：0571-89996203",
        "移动电话：15925933033/18605077656",
        "传真：0571-89996203",
        "邮箱：gfxyzcosmos@gmail.com",
        "网站：https://shangshuroom.com"
    ]
}

export function question(){
    return [
        {
            title:"当我订购艺术品时,我会得到什么?",
            text:[
                "您会得到一个该艺术品的实物产品，产品会以精美的包装快递或者定制配送到您手里。"
            ],
            id:"00000001"
        },
        {
            title:"在哪里可以看到我的优惠券？",
            text:[
                "领取优惠券之后，请您登录上殊房，点击“我的”，进入“优惠券”，​查看您已领取的优惠券。我的卡券分为三个模块：未使用、已使用、已失效。",
                "注：如果您没有看见已领取的优惠券，可能因为：您领取时使用的账号与您本次登录的账号不一致。您之前领取的优惠券已过期/已使用。",
            ],
            id:"00000002"
        },
        {
            title:"优惠券如何使用？",
            text:[
                "1.优惠券分为：满减优惠券、现金券和折扣券。满减券需满足指定金额可用；",
                "2.单笔订单只能使用1张优惠券，不支持同时使用多张，用券后差额不找零，不兑换现金。",
                "3. 使用优惠券的订单若产生退货，需订单内所有商品均退货退款成功，且优惠券仍在有效期内，可返回优惠券，退回后的优惠券有效期不变。",
                "* 若只申请部分退款且退款成功，优惠券将不予退回。",
                "* 退回优惠券如已过期，则不再补发。",
                "4.请在有效期内使用优惠券，未使用的优惠券过期后，将自动作废。"
            ],
            id:"00000003"
        }
    ]
}

export function orderTabList(){
    return [
        {
            text:"全部",
            id:"00000001"
        },{
            text:"待支付",
            id:"00000002"
        },{
            text:"已完成",
            id:"00000003"
        }
    ]
}

export function footer(){
    return [
        {
            src:require("@/assets/img/quality/1.png"),
            title:"多维度展示",
            content:"全方位的、立体的体验视觉之美",
            id:"00000001"
        },
        {
            src:require("@/assets/img/quality/2.png"),
            title:"品质保证",
            content:"限量发售",
            id:"00000002"
        },
        {
            src:require("@/assets/img/quality/3.png"),
            title:"同多位艺术家合作",
            content:"携手多位艺术家共同打造更多精美作品",
            id:"00000003"
        },
    ]
}

export function footerAbout(){
    return [
        {
            title:"关于",
            content:[
                "“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前。而“殊”则是特别，将每一件作品的殊胜之处，背后的细腻情感传达到您的心里。众多艺术家们将与上殊房一起缔造一个更丰富多彩的多维空间，呈现众多杰出艺术作品的进行时和在新的宇宙空间里的将来时。希望喜欢、热爱艺术的你，成为上殊房的一份子，一起去聆听艺术最细腻的声音。将更多的欢喜和慰藉带给自己，也带给身边的人。",
            ],
            id:"10000001"
        },
        {
            title:"信息",
            links:[
                {
                    path:"/about",
                    label:"关于",
                    id:"100000021"
                },
                {
                    path:"/reg",
                    label:"服务条款",
                    id:"100000022"
                },
                {
                    path:"/policy",
                    label:"隐私政策",
                    id:"100000023"
                },
                // {
                //     path:"/about",
                //     label:"关于我们",
                //     id:"100000024"
                // },
                {
                    path:"/relation",
                    label:"联系我们",
                    id:"100000025"
                },
            ],
            id:"10000002"
        },
    ]
}

export function regAgreement(){
    return [
        {
            title:"一、注册协议的确认及接受",
            id:"01000000",
            content:[
                {
                    text:"1、上殊房网站（下简称“本网站”）各项电子服务的所有权和运作权归属于杭州寸间大造文化发展有限公司（下简称“寸间大造”）所有，请您审慎阅读并选择接受或不接受本协议（限制民事行为能力人应在监护人或法定代理人陪同下阅读并进行选择）。除非您接受本协议所有条款，否则您无权注册、使用本网站。您确认所有服务条款并完成注册程序时，即视为您已同时您成为本网站正式用户。",
                    id:"01000001",
                    content:[]
                },
                {
                    text:"2、根据国家法律法规变化及本网站运营需要，寸间大造及本网站有权对本协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在本网站上即生效，并取代此前相关内容，您应不时关注本网站公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用本网站；如您继续使用本网站，即视为知悉变动内容并同意接受。",
                    id:"01000002",
                    content:[]
                },
                {
                    text:"3、特别提醒您认真阅读、充分理解本协议各条款，特别是涉及您的权利与义务，免除或者限制本网站所有者责任的条款，隐私政策和法律适用条款等，本协议内容中以加粗方式显著标识的条款，请您着重阅读。您点击“同意”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。您的注册和使用行为将视为您已充分理解本协议，并同意接受本协议各项条款的约束。",
                    id:"01000003",
                    content:[]
                }
            ]
        },
        {
            title:"二、服务须知",
            id:"02000000",
            content:[
                {
                    text:"1、本网站运用自身开发的操作系统通过国际互联网络为用户提供购买商品等服务。使用本网站，您必须：",
                    id:"02000001",
                    content:[
                        "（1）自行配备上网的所需设备，包括个人手机、平板电脑、调制解调器、路由器等；",
                        "（2）自行负担个人上网所支付的与此服务有关的电话费用、网络费用等；",
                        "（3）选择与所安装终端设备相匹配的软件版本，包括但不限于iOS、Android、Windows等多个寸间大造发布的应用版本。"
                    ]
                },
                {
                    text:"2、基于本网站所提供的网络服务的重要性，您确认并同意：",
                    id:"02000002",
                    content:[
                        "（1）提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新；",
                        "（2）如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且寸间大造保留终止您使用本网站各项服务的权利。"
                    ]
                }
            ]
        },
        {
            title:"三、订单",
            id:"03000000",
            content:[
                {
                    text:"1、使用本网站下订单，您应具备购买相关商品的权利能力和行为能力，如果您在18周岁以下，您需要在监护人的监护参与下才能注册并使用本网站。在下订单的同时，即视为您满足上述条件，并对您在订单中提供的所有信息的真实性负责。",
                    id:"03000001",
                    content:[]
                },
                {
                    text:"2、在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。",
                    id:"03000002",
                    content:[]
                },
                {
                    text:"3、您理解并同意：本网站上销售商展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；您下单并支付货款后即视为您与销售商之间成立了合同关系，系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，是您与销售商之间的合同内容。",
                    id:"03000003",
                    content:[]
                },
                {
                    text:"4、本网站内的商品通过第三方支付机构（包括支付宝、微信支付及银联、网联等支付通道）进行款项支付结算，您应确保您使用的第三方支付机构账号为您自有账号，并同时确保您自有的第三方支付机构账号未被他人盗用，您下单并付款后，将默认您的第三方支付机构账号系在您控制下完成支付行为。您同意并确认，在过了法定退款期后，支付账号误操作、被他人盗用等将不成为您向本网站、寸间大造主张权利的理由。",
                    id:"03000004",
                    content:[]
                },
                {
                    text:"5、除另有书面约定外，您在本网站内购买的商品（包括现不限于原件、复印件、打印件、3D打印件等，下同）的一切知识产权（包括但不限于著作权等）在您购买后仍归属该商品的创作者所有。",
                    id:"03000005",
                    content:[]
                },               
                {
                    text:"6、本网站内的所有数字凭证均为相关权益凭证（具体权益及使用规则以本网站上的该权益凭证的具体表述为准），您不得单独基于数字凭证提起退款、更换等要求。",
                    id:"03000006",
                    content:[]
                },               
                {
                    text:"7、您同意并确认，您在本网站内获取的数字凭证均委托本网站进行管理（包括但不限于数据存储、展示等），本网站您的数字凭证所提供的管理系本网站提供的免费服务。除本网站故意行为导致外，其他原因导致的相关数字凭证的损毁与本网站及本网站所有者无关；本网站有权随时终止向您的数字凭证提供上述免费管理服务，届时您应及时将您的数字凭证转移至其他存储平台，否则造成的损失与本网站及本网站所有者无关。",
                    id:"03000007",
                    content:[]
                },               
                {
                    text:"8、非因实物产品质量问题发生的退、换货所导致的损失（包括但不限于物流费用等）将由购买者承担。",
                    id:"03000008",
                    content:[]
                },               
                {
                    text:"9、发生退款事项时，销售商在收到退货后再退款，所退款项为该实物产品的实际销售金额（不包括该产品优惠券、抵用券等权益凭证的金额）。",
                    id:"03000009",
                    content:[]
                },               
                {
                    text:"10、尽管销售商做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，本网站无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，您有权取消订单，销售商亦有权依法、依约取消订单，若您已经付款，则为您办理退款，并提供订单处理方案。",
                    id:"03000010",
                    content:[]
                }
            ]
        },
        {
            title:"四、配送和交付",
            id:"04000000",
            content:[
                {
                    text:"1、您在本网站购买的商品将按照本网站上您所指定的送货地址进行配送。订单信息中列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。您应当清楚准确地填写您的送货地址、联系人及联系方式等配送信息，您知悉并确认，您所购买的商品应仅由您填写的联系人接受身份查验后接收商品，因您变更联系人或相关配送信息而造成的损失由您自行承担。",
                    id:"04000001",
                    content:[]
                },
                {
                    text:"2、因如下情况造成订单延迟或无法配送等，本网站及网站所有者将无法承担迟延配送或无法配送的责任：",
                    id:"04000002",
                    content:[
                        "（1）客户提供错误信息和不详细的地址；",
                        "（2）货物送达无人签收或拒收，由此造成的重复配送所产生的费用及其他相关后果。",
                        "（3）不可抗力，例如：自然灾害、疫情及恶劣天气、交通戒严等政府、司法机关的行为、决定或命令、意外交通事故、罢工、法规政策的修改、恐怖事件、抢劫、抢夺等暴力犯罪、突发战争等。"
                    ]
                },
                {
                    text:"3、您在本网站购买的实物商品由第三方配送公司（包括顺丰、圆通等，以下称“配送公司”）为您完成订单交付的，系统或单据记录的签收时间为交付时间；电子数据产品若采用在线传输方式交付的，销售商向您指定系统发送的时间为交付时间；您购买服务的，生成的电子或者实物凭证中载明的时间为交付时间。",
                    id:"04000003",
                    content:[]
                },
                {
                    text:"4、您应在前述交付时间后12小时内完成对相关商品的验收，发现问题的及时向客服反馈，逾期未验收的，视为已验收合格。",
                    id:"04000004",
                    content:[]
                },
            ]
        },
        {
            title:"五、用户个人信息保护及授权",
            id:"05000000",
            content:[
                {
                    text:"1、您知悉并同意，为方便您使用本网站相关服务，本网站将存储您在使用时的必要信息，包括但不限于您的真实姓名、性别、生日、配送地址、联系方式、通讯录、相册、日历、定位信息等。除法律法规规定的情形外，未经您的许可寸间大造不会向第三方公开、透露您的个人信息。",
                    id:"05000001",
                    content:[]
                },
                {
                    text:"2、您知悉并确认，您在注册帐号或使用本网站的过程中，需要提供真实的身份信息，寸间大造将根据国家法律法规相关要求，进行基于移动电话号码的真实身份信息认证；若您以您的微信号注册、使用本网站的，本网站将默认您的微信号对应的为您的真实身份信息。若您提供的信息不真实、不完整，则无法使用本网站或在使用过程中受到限制，同时，由此产生的不利后果，由您自行承担。",
                    id:"05000002",
                    content:[]
                },
                {
                    text:"3、您在使用本网站某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您已阅读并同意接受相关单独协议。",
                    id:"05000003",
                    content:[]
                },
                {
                    text:"4、您充分理解并同意：",
                    id:"05000004",
                    content:[
                        "（1）接收通过邮件、短信、电话等形式，向在本网站注册、购物的用户、收货人发送的订单信息、促销活动等内容；",
                        "（2）为配合行政监管机关、司法机关执行工作，在法律规定范围内寸间大造有权向上述行政、司法机关提供您在使用本网站时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等；",
                        "（3）寸间大造依法保障您在安装或使用过程中的知情权和选择权，在您使用本网站服务过程中，涉及您设备自带功能的服务会提前征得您同意，您一经确认，寸间大造有权开启包括但不限于收集地理位置、读取通讯录、使用摄像头、启用录音等提供服务必要的辅助功能。",
                        "（4）寸间大造有权根据实际情况，在法律规定范围内自行决定单个用户在本网站及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。"
                    ]
                }
            ]
        },
        {
            title:"六、用户行为规范",
            id:"06000000",
            content:[
                {
                    text:"1、您同意严格遵守法律法规规章规定，依法遵守以下义务：",
                    id:"06000001",
                    content:[
                        "（1）不得制作、传输或发表以下违法信息资料：反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律法规实施的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，煽动推翻社会主义制度的；损害国家荣誉和利益的；歪曲、丑化、亵渎、否定英雄烈士事迹和精神，侵害英雄烈士的姓名、肖像、名誉、荣誉的；宣扬或煽动实施恐怖主义、极端主义及其活动的；煽动民族仇恨、民族歧视、破坏民族团结的言论；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱经济秩序和社会秩序的；散布淫秽、色情、暴力或者教唆犯罪的；侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；法律、行政法规禁止的其他内容。",
                        "（2）防范和抵制制作、复制、发布含有下列内容的不良信息资料：标题严重夸张，发表内容与标题严重不符的；不当评述自然灾害、重大事故等灾难的；煽动人群歧视、地域歧视等的；宣扬低俗、庸俗、媚俗内容的；违反社会公德行为的；侵犯未成年人合法权益的；其他对网络生态造成不良影响的内容。",
                        
                    ]
                },
                {
                    text:"2、本协议依据国家相关法律法规规章制定，您亦同意严格遵守以下义务：",
                    id:"06000002",
                    content:[
                        "（1）从中国大陆向境外传输资料信息时必须符合中国有关法规；",
                        "（2）不得利用本网站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；",
                        "（3）不得干扰本网站的正常运转，不得侵入本网站及国家计算机信息系统；",
                        "（4）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、不文明的等信息资料；",
                        "（5）不得教唆他人从事违法违规或本协议、平台规则所禁止的行为；",
                        "（6）不得利用在本网站注册的账户进行牟利性经营活动；",
                        "（7）不得发布任何侵犯他人个人信息、著作权、商标权等知识产权或合法权利的内容；"
                    ]
                },
                {
                    text:"3、您须对自己在网上的言论和行为承担法律责任，您若在本网站上散布和传播反动、色情或其它违反国家法律的信息，本网站的系统记录有可能作为您违反法律的证据。",
                    id:"06000003",
                    content:[]
                }
            ]
        },
        {
            title:"七、本网站使用规范",
            id:"07000000",
            content:[
                {
                    text:"1、本网站的获取与更新：",
                    id:"07000001",
                    content:[
                        "（1）您可以直接从互联网中搜索本网站官网（https://shangshuroom.com）并获取本网站，如果您从未经寸间大造授权的第三方获取软件或与寸间大造移动客户端软件名称相同的安装程序或与本网站中文名相同的仿制网站，寸间大造无法保证该软件、网站能够正常使用，并对因此给您造成的损失不予负责；",
                        "（2）为了改善用户体验、完善服务内容，寸间大造将不断努力开发新的服务，并为您不时提供维护、更新，新版本更新后，旧版本可能无法使用，寸间大造不保证旧版继续可用及提供相应的客户服务，请您随时核对并更新使用最新版本。",
                        
                    ]
                },
                {
                    text:"2、除非法律允许或寸间大造书面许可，您使用本网站过程中不得从事下列行为：",
                    id:"07000002",
                    content:[
                        "（1）删除本网站及其副本上关于著作权的信息；",
                        "（2）对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本网站的源代码；",
                        "（3）对寸间大造拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；",
                        "（4）对本网站或者本网站运行过程中释放到任何终端内存中的数据、网站运行过程中客户端与服务器端的交互数据，以及本网站运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经寸间大造授权的第三方工具/服务接入本网站和相关系统；",
                        "（5）通过修改或伪造网站运行中的指令、数据，增加、删减、变动网站的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；",
                        "（6）通过非寸间大造开发、授权的第三方软件、插件、外挂、系统，登录或使用本网站及服务，或制作、发布、传播上述工具；",
                        "（7）自行或者授权他人、第三方软件对本网站及其组件、模块、数据进行干扰。"
                    ]
                }
            ]
        },
        {
            title:"八、数字凭证特别约定",
            id:"08000000",
            content:[
                {
                    text:"1、数字凭证的一切知识产权均由销售商或者原创者所有，除另行取得知识产权所有者书面同意外，用户仅可按该数字凭证的具体权益使用规则进行使用，或为个人收藏、学习、研究、欣赏、及展示的目的使用，不得将数字凭证用于其他任何商业用途或用于其他违法、犯罪行为。",
                    id:"08000001",
                    content:[]
                },
                {
                    text:"2、请勿对数字凭证进行炒作、交易、欺诈或已任何其他非法方式进行使用。如用户出现涉嫌违法违规行为（例如洗钱、虚假交易、作弊、套现、赌博、刷信用、欺诈、炒作等），本网站有权回收用户已经取得的数字凭证及相关权益。",
                    id:"08000002",
                    content:[]
                }
            ]
        },
        {
            title:"九、违约责任",
            id:"09000000",
            content:[
                {
                    text:"1、如果寸间大造发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，寸间大造有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁、注销等处罚，并公告处理结果。",
                    id:"09000001",
                    content:[]
                },
                {
                    text:"2、寸间大造有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。",
                    id:"09000002",
                    content:[]
                },
                {
                    text:"3、您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；寸间大造或本网站因此遭受损失的，您也应当一并赔偿。",
                    id:"09000003",
                    content:[]
                },
                {
                    text:"4、除非另有明确的书面说明,寸间大造不对本网站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。",
                    id:"09000004",
                    content:[]
                }
            ]
        },
        {
            title:"十、所有权及知识产权",
            id:"10000000",
            content:[
                {
                    text:"1、您一旦接受本协议，对于您提供、上传、创作、发布在本网站的文字、评论、图片、照片、视频、音频等任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等），您免费授予寸间大造及其关联公司至保护期终止为止、不可撤销的、全球范围的、排他的许可使用著作权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可许可使用的权利）、肖像权、用户ID名称等相关的全部合法权利权益，就寸间大造具有诉讼主体资格的权利、权益，寸间大造有权以自身名义对第三方侵权行为取证及提起诉讼。您同意寸间大造及其关联公司有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播您以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。",
                    id:"10000001",
                    content:[]
                },
                {
                    text:"2、寸间大造有权再许可给其他第三方第九条第一款的所有权利。",
                    id:"10000002",
                    content:[]
                },
                {
                    text:"3、您应确保在寸间大造网站上发表的第九条第一款各类信息内容均不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则寸间大造有权随时采取包括但不限于删除、断开链接等措施。",
                    id:"10000003",
                    content:[]
                },
                {
                    text:"4、本协议已经构成《中华人民共和国著作权法》第二十四条（条文序号依照2010年修订版《著作权法》确定）及相关法律规定的著作财产权等权利许可使用的书面协议，其效力及于您在本网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。",
                    id:"10000004",
                    content:[]
                },
                {
                    text:"5、您同意并已充分了解本协议的条款，承诺不将已发表于本网站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。",
                    id:"10000005",
                    content:[]
                },
                {
                    text:"6、除法律另有强制性规定外，未经寸间大造明确的特别书面同意,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容，否则，寸间大造有权追究其法律责任。",
                    id:"10000006",
                    content:[]
                },
                {
                    text:"7、本网站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件）及产品（包括但不限于实物产品、数字凭证等）的知识产权，均是寸间大造或其内容提供者的财产，受中国和国际版权法的保护。本网站上所有内容的汇编是寸间大造的排他财产，受中国和国际版权法的保护。本网站上所有软件、代码都是寸间大造或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。",
                    id:"10000007",
                    content:[]
                }
            ]
        },
        {
            title:"十一、法律管辖适用及其他",
            id:"11000000",
            content:[
                {
                    text:"1、本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，应向协议签订地有管辖权的人民法院提起诉讼。本协议签订地为中华人民共和国杭州市上城区。",
                    id:"11000001",
                    content:[]
                },
                {
                    text:"2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。",
                    id:"11000002",
                    content:[]
                },
                {
                    text:"3、本协议未明示授权的其他权利仍由寸间大造保留，您在行使这些权利时须另外取得寸间大造的书面许可。寸间大造如果未行使前述任何权利，并不构成对该权利的放弃。",
                    id:"11000003",
                    content:[]
                }
            ]
        },
    ]
}


export function policyContent(){
    return [
        {
            title:"上殊房网站（以下简称“本网站”）由杭州寸间大造文化发展有限公司（以下称“寸间大造”） 提供。寸间大造尊重并保护用户（以下称“用户”或“您”）隐私，寸间大造隐私政策（以下称“本隐私政策”）介绍了寸间大造在产品/服务中收集、使用、共享您的个人信息的目的、方式和范围，寸间大造相关的信息安全保护措施，以及您访问、控制您个人信息的方法。请您完整阅读本隐私政策，提请您注意并完整理解这些内容。当您访问、使用本网站，或您使用本网站、寸间大造产品/服务，而阅读并同意本隐私政策时，即表示您已充分理解并完全同意本网站、寸间大造按照本隐私政策来处理相关数据和信息。如果您不同意本隐私政策的任何内容，您应立即停止使用本网站、寸间大造产品/服务。",
            id:"01000000",
            content:[
                {
                    text:"",
                    id:"01000001",
                    content:[]
                }
            ]
        },

        {
            title:"本隐私政策可由本网站随时更新，更新后的隐私政策一旦公布即代替原来的隐私政策，本网站将会通过电子邮件或网站公告等方式提示您。如果您不同意对本隐私政策所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本隐私政策所做的修改。",
            id:"02000000",
            content:[
                {
                    text:"",
                    id:"02000001",
                    content:[]
                }
            ]
        },
        {
            title:"本隐私政策包含以下内容：",
            id:"03000000",
            content:[
                {
                    text:"一、我们的承诺",
                    id:"03000001",
                    content:[]
                },{
                    text:"二、我们收集个人信息的目的及范围",
                    id:"03000002",
                    content:[]
                },{
                    text:"三、我们如何收集个人信息",
                    id:"03000003",
                    content:[]
                },{
                    text:"四、我们如何使用个人信息",
                    id:"03000004",
                    content:[]
                },{
                    text:"五、我们如何存储个人信息",
                    id:"03000005",
                    content:[]
                },{
                    text:"六、我们如何保护个人信息",
                    id:"03000006",
                    content:[]
                },{
                    text:"七、我们如何使用COOKIES等自动收集工具",
                    id:"03000007",
                    content:[]
                },{
                    text:"八、您对个人信息的控制",
                    id:"03000008",
                    content:[]
                },{
                    text:"九、免责声明",
                    id:"03000009",
                    content:[]
                },{
                    text:"十、未成年人个人信息特别约定",
                    id:"03000010",
                    content:[]
                },{
                    text:"十一、如何联系我们",
                    id:"03000011",
                    content:[]
                },{
                    text:"十二、适用法律与争议解决渠道",
                    id:"03000012",
                    content:[]
                },
            ]
        },
        {
            title:"一、我们的承诺",
            id:"04000000",
            content:[
                {
                    text:"1. 我们仅在必要的、合理的范围内收集、处理您的个人信息",
                    id:"04000001",
                    content:["我们承诺仅在必要、合理的范围内收集、处理您的个人信息。我们收集、处理您的个人信息是为了遵守国家法律法规的规定，以及通过我们的本网站向您提供个性化、更优质的产品或服务（包括支持我们开发新产品或服务，或完善已有产品或服务功能。）"]
                },{
                    text:"2. 我们仅在您知悉并同意的前提下收集、处理您的个人信息",
                    id:"04000002",
                    content:["我们承诺会公开坦诚地告知您何种个人信息被收集、处理，及其原因、方式。我们尊重您的选择，我们收集、处理您的个人信息的范围、目的及方式如有任何重大改变时，我们将及时告知您。"]
                },{
                    text:"3. 我们尊重您对个人信息的管理",
                    id:"04000003",
                    content:["我们确保您提供的个人信息不被恶意篡改、损毁或缺失。我们理解并尊重您对个人信息的关切，并为您提供个人信息的查询、更正及删除等渠道。"]
                },{
                    text:"4. 我们将采取必要的措施保证您的个人信息安全",
                    id:"04000004",
                    content:["我们承诺在能力范围内采取必要的安全措施，以保障您的个人信息安全，包括但不限于通过符合适用的法律法规及标准要求的硬件、软件、数据、人员、物理环境及其基础设施防护措施保护您的个人信息安全。"]
                }
            ]
        },
        {
            title:"二、我们收集个人信息的目的及范围",
            id:"05000000",
            content:[
                {
                    text:"本隐私政策所指的“个人信息”是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。当您访问、使用本网站时，或您使用我们的产品或服务时，您会向我们主动提供您的个人信息，我们也会通过自动收集工具、第三方收集您的信息。我们收集信息的目的及范围：",
                    id:"05000001",
                    content:[]
                }, {
                    text:"1.用于身份识别，或联系您，或完善我们的服务体系（例如确保您需要的服务信息更加精准、便捷、高效地到达）的个人信息，包括您在本网站的注册账户，或使用我们提供的各项产品或服务时您向我们提供的包括但不限于您的本网站注册信息、真实姓名、身份证信息、居住地址、电话号码、即时通信账户信息、电子邮件地址、生物信息（例如声纹、指纹）等信息。",
                    id:"05000002",
                    content:[]
                }, {
                    text:"2.用于向您提供产品或服务的个人信息，包括但不限于您的购买产品或服务的信息、您购买的频率、您消费的金额、您使用该产品或服务的日期、您的第三方支付账户、银行卡类型、持卡人姓名、卡号、开户行等信息。",
                    id:"05000003",
                    content:[]
                }, {
                    text:"3.用于向您出具发票所需的个人信息，包括但不限于您的电子邮箱、姓名、开票单位名称、购买日期和支付的金额等信息。",
                    id:"05000004",
                    content:[]
                }, {
                    text:"4.此外，为了完善和提升我们的产品或服务的质量和精准度，我们还会收集包括但不限于您的IP地址、设备标识符、硬件型号、操作系统版本，与我们的服务相关的日志信息，以及您的学历、职业信息、婚姻状况等信息。",
                    id:"05000005",
                    content:[]
                },
            ]
        },
        {
            title:"三、我们如何收集个人信息",
            id:"06000000",
            content:[
                {
                    text:"1. 您直接提供给我们的信息",
                    id:"06000001",
                    content:["您通过本网站注册我们的账户、申请或使用我们的产品或服务，或通过电子邮件、电话或其他方式联系我们时，可能向我们提供一些个人信息（具体范围详见“我们收集个人信息的目的及范围”部分）。若您不提供这些信息或提供的信息有误，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。"]
                },
                {
                    text:"2. 来自本网站主动收集的信息",
                    id:"06000002",
                    content:["除了您提供给我们的个人信息外，我们可能直接通过COOKIES等自动收集工具收集您的信息，如您的IP地址、设备标识号、硬件型号、操作系统版本、位置以及与我们的服务相关的日志信息。若您拒绝我们收集此类信息，我们将无法全部或部分为您提供相关产品或服务的功能，但不会影响我们向您提供核心的产品或服务。"]
                },
                {
                    text:"3. 来自商业合作伙伴的信息",
                    id:"06000003",
                    content:["我们可能会从商业合作伙伴处获得您的信息，包括但不限于您在其他商业合作伙伴处的交易信息（包括交易的商品名称及金额等），您的出行信息等。若您拒绝授权或同意我们获取这些信息，可能导致我们无法全部或部分提供产品或服务，或影响您获取产品或服务的等级、数量和类型。"]
                },
                {
                    text:"4. 来自其他第三方的信息",
                    id:"06000004",
                    content:["我们可能从第三方获取您授权共享的账户信息（头像、昵称），并在您同意本隐私政策后将您的第三方账户与您的账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。"]
                },
                {
                    text:"5. 来自您的分享",
                    id:"06000005",
                    content:["当您使用我们的产品或服务与家人和朋友分享内容、产品，或邀请他人使用我们的产品或服务时，或者在必要情形下需通过您的家人和朋友与您进行联系时，我们可能会收集您提供的与上述人士有关的信息，如姓名、邮寄地址、电子邮件地址以及电话号码等。在您向寸间大造提供上述人士的相关信息之前，您确保您已获得对方的授权同意。若您拒绝我们收集此类信息，我们将无法完成相关服务咨询或者及时与您联系。"]
                },
                {
                    text:"6. 在法律法规允许的范围内，我们可能会在以下情形中收集并使用您的个人信息无需经过您的授权同意：",
                    id:"06000006",
                    content:[
                        "1)  与国家安全、国防安全有关的；",
                        "2)  与公共安全、公共卫生、重大公共利益有关的；",
                        "3)  与犯罪侦查、起诉、审判和判决执行等有关的；",
                        "4)  出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的；",
                        "5)  所收集的个人信息是您自行向社会公众公开的；",
                        "6) 从合法公开披露的信息中收集到的个人信息，如合法的新闻报道、政府信息公开等渠道；",
                        "7)  学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；",
                        "8)  法律法规规定的其他情形。"
                    ]
                }
            ]
        },
        {
            title:"四、我们如何使用个人信息",
            id:"07000000",
            content:[
                {
                    text:"我们在不同的场景中，使用您个人信息的目的、方式和范围略有差异，具体如下：",
                    id:"07000001",
                    content:[]
                },
                {
                    text:"1. 向您提供我们的各项产品或服务，或支持、改进我们产品或服务的质量，或用于可能的争议解决：",
                    id:"07000002",
                    content:["我们可能会使用您直接提供给我们的，或是来自本网站、商业合作伙伴及其他第三方的信息，包括但不限于您真实身份信息、电子身份信息、职业信息、账户信息等。在您购买我们的各项产品或服务后，您可以选择本网站的关联方或与本网站合作的第三方支付机构（包括支付宝、微信支付及银联、网联等支付通道， 以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。"]
                },
                {
                    text:"2. 向第三方机构进行身份信息的验证及匹配：",
                    id:"07000003",
                    content:["我们可能会使用您直接提供给我们的，或是来自商业合作伙伴及其他第三方的信息，包括但不限于您的第三方支付账户、银行卡类型、持卡人姓名、卡号、开户行等信息。"]
                },
                {
                    text:"3. 用于帮助您更好地使用我们的各项产品或者服务：",
                    id:"07000004",
                    content:["我们可能会使用您直接提供给我们的，或是来自本网站、商业合作伙伴及其他第三方的信息，包括但不限于您的真实身份信息、电子身份信息、生物信息、不动产信息、职业信息、账户信息、出行信息等信息，您在本网站所购买的产品或服务的交易信息、其他商业合作伙伴处的交易信息，以及您主动提供的家人或朋友的姓名、邮寄地址、电子邮件地址、电话号码等信息。在您向寸间大造提供上述人士的相关信息之前，您确保您已获得对方的授权同意。"]
                },
                {
                    text:"4. 使您知晓自己使用的我们各项产品或服务的情况，或了解我们的其他服务，在您同意的前提下，向您发送服务状态通知，展现或推荐相关程度更高的产品、信息流或广告、推广信息及优惠信息：",
                    id:"07000005",
                    content:["我们可能会使用您直接提供给我们的，或是来自本网站、商业合作伙伴及其他第三方的信息，包括但不限于您的真实身份信息、电子身份信息、IP地址、设备标识符、硬件型号、操作系统版本，与我们的服务相关的日志信息，您的学历、职业信息、婚姻状况，以及您在本网站所购买的产品或服务的交易信息、其他商业合作伙伴处的交易信息。"]
                },
                {
                    text:"5. 与您沟通或回应您的疑问和请求，或是向您提供更新信息，包括但不限于本网站用户服务协议、本隐私政策、条款和条件方面的变动或其他我们认为需要告知您的变动。",
                    id:"07000006",
                    content:["当您使用我们的产品或服务与家人和朋友分享内容、产品，或邀请他人使用我们的产品或服务时，或者在必要情形下需通过您的家人和朋友与您进行联系时，我们可能会收集您提供的与上述人士有关的信息，如姓名、邮寄地址、电子邮件地址以及电话号码等。在您向寸间大造提供上述人士的相关信息之前，您确保您已获得对方的授权同意。若您拒绝我们收集此类信息，我们将无法完成相关服务咨询或者及时与您联系。"]
                },
                {
                    text:"6. 用于监测我们本网站的性能和稳定性，优化性能、支持、改进线上服务质量：",
                    id:"07000007",
                    content:["我们可能通过COOKIES等自动收集工具收集的信息，包括但不限于您的IP地址、设备标识号、硬件型号、操作系统版本、位置以及与我们的服务相关的日志信息等。"]
                },
                {
                    text:"7. 用于监测我们本网站的性能和稳定性，优化性能、支持、改进线上服务质量：",
                    id:"07000008",
                    content:["在当您下单并在线完成支付后，本网站的关联方或与本网站合作的第三方配送公司（包括顺丰、圆通等，以下称“配送公司”）将为您完成订单的交付。您知晓并同意本网站关联方或与本网站合作的第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。"]
                },
                {
                    text:"8. 其他您个人信息的潜在用途",
                    id:"07000009",
                    content:[
                        "1)  我们通过技术手段对您的个人信息进行去标识化处理使信息无法识别主体的情况下，对该等数据的挖掘、分析/利用（包括商业化利用）和与第三方共享将无须再获得您的同意或授权；",
                        "2)  预防或禁止非法的活动；",
                        "3)  遵守法律法规的义务；",
                        "4)  经您许可的其他用途。"
                    ]
                },
            ]
        },
        {
            title:"五、我们如何存储个人信息",
            id:"08000000",
            content:[
                {
                    text:"1. 保存的地域",
                    id:"08000001",
                    content:["您的个人信息储存于中华人民共和国境内，我们不会在境外存储您的个人信息。"]
                },{
                    text:"2. 保存的期限",
                    id:"08000002",
                    content:[
                        "除非法律法规另有规定，我们将按如下方式及期间保存您的信息：",
                        "1)  您使用我们产品或服务期间，我们将综合考虑为您提供产品或服务的必要性，根据业务需要持续为您保存或删除个人信息；",
                        "2)  但是，即便有第1) 款之规定，您仍有权自主决定删除这些信息或者在相关技术设置允许条件下注销账户；",
                        "3)  进一步的，即便有第2) 款之规定，在某些特定情况下，有且仅为了遵守国家安全、网络安全等法律规定的法定义务或政府命令，我们仍将继续保存您的信息，直至法定义务免除。",
                        ]
                },{
                    text:"3. 跨境转移",
                    id:"08000003",
                    content:["除非获得您的特别同意，或者满足法律规定的评估及许可程序，我们不会跨境转移您的个人信息。"]
                }
            ]
        },
        {
            title:"六、我们如何保护个人信息",
            id:"09000000",
            content:[
                {
                    text:"1. 我们会采取适当的、符合业界标准的安全措施和技术手段，以保护您的个人信息，包括但不限于制定了严格的内部信息管理流程、对数据进行加密、定岗定责、控制访问权限、采取安全措施等，以防止其丢失、被非法使用、受到未授权访问或泄漏、被篡改或毁坏。",
                    id:"09000001",
                    content:[]
                },{
                    text:"2. 我们仅允许有必要知晓这些信息的人员，在采取合理的措施验证身份之后，访问或使用这些信息。同时，我们会严格要求他们履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与我们的劳动、合作关系。",
                    id:"09000002",
                    content:[]
                },{
                    text:"3. 为了保护您的信息安全，如果您的个人信息有误，我们会在严格验证并核实申请人身份后，根据您的要求访问、修正或删除相关信息（除非我们出于合法的原因而必须保留这些个人信息）。",
                    id:"09000003",
                    content:[]
                },{
                    text:"4. 如果一旦发生安全事件影响到您的个人信息安全的，我们将通过您预留的邮箱、电话、系统推送等方式通知您，并告知您降低或防范相关风险的建议等信息。",
                    id:"09000004",
                    content:[]
                },{
                    text:"5. 必要时，我们将根据适用的法律、法规通知主管部门。同时，我们会及时处置系统漏洞、网络攻击、病毒侵袭及网络侵入等安全风险。在发生危害网络安全的事件时，我们会按照网络安全事件应急预案，及时采取相应的补救措施，并按照规定向有关主管部门报告。",
                    id:"09000005",
                    content:[]
                }
            ]
        },
        {
            title:"七、我们如何使用COOKIES等自动收集工具",
            id:"10000000",
            content:[
                {
                    text:"1. 当您使用本网站时，我们使用COOKIES和其他类似技术来记录信息，包括但不限于您的IP地址、设备标识符、硬件型号、操作系统版本、位置以及与我们的服务相关的日志等信息。此等技术帮助我们更好地了解用户的行为，告诉我们您浏览了我们网站的哪些部分，以及您正在处理的交易，并衡量广告和网络搜索的效果并加以改善。",
                    id:"10000001",
                    content:[]
                },{
                    text:"2. 请您理解，我们本网站中的许多COOKIES起着至关重要的作用：例如，当您登录我们账号时，COOKIES记录着您的账号信息，使您省去重复输入注册信息等步骤，或帮助您判断您的账户安全。其它的COOKIES帮助我们了解您使用我们本网站的方式，从而使我们改进线上工作。对此我们使用相关的分析工具分析我们本网站的加载时间、访客使用的方式，以及我们本网站的访客查看最多的信息。它们还帮助我们发现我们本网站中没有达到预期效果的部分，促使我们采取修复措施，为您打造更好用的本网站。",
                    id:"10000002",
                    content:[]
                }
            ]
        },        {
            title:"八、您对个人信息的控制",
            id:"11000000",
            content:[
                {
                    text:"1. 我们非常尊重您对个人信息的控制权限，并为您提供了控制您个人信息的方法。您有权利查询、更正、管理、删除自己的个人信息并保护自己的隐私。",
                    id:"11000001",
                    content:[]
                },{
                    text:"2. 您对个人信息的更新、管理或删除的行为，我们原则上不收取费用，但对于多次重复、超出合理限度的请求，我们将视情况收取一定成本费用或者延期、拒绝响应您的请求。",
                    id:"11000002",
                    content:[]
                },{
                    text:"3. 如果您想访问、更新、管理或删除您的个人信息，您可以通过如下方式完成：",
                    id:"11000003",
                    content:[
                        "1)  在相关技术设置允许的情况下，登陆您的本网站注册账户访问、更新、管理或删除；",
                        "2)  通过“联系方式”部分公布的方式，联系我们，我们非常愿意为您提供可能的协助。",
                    ]
                },{
                    text:"4.  在以下情形中，按照法律法规要求，我们将无法响应您的更新、管理或删除等请求：",
                    id:"11000004",
                    content:[
                        "1)  与国家安全、国防安全有关的；",
                        "2)  与公共安全、公共卫生、重大公共利益有关的；",
                        "3)  与犯罪侦查、起诉和审判等有关的；",
                        "4)  有充分证据表明您存在主观恶意或滥用权利的；",
                        "5)  响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。"
                    ]
                }
            ]
        },        {
            title:"九、免责声明",
            id:"12000000",
            content:[
                {
                    text:"1. 请您理解，互联网并非绝对安全的环境，电子邮件、即时通讯、社交软件、交易本网站等与其他用户的交流方式无法确定是否完全加密，请您在进行交互使用时，注意保护您个人信息的安全。",
                    id:"12000001",
                    content:[]
                },{
                    text:"2. 请您理解，由于计算机及互联网技术的飞速发展及同步速度的限制，可能存在或出现各种恶意或非恶意的攻击手段。虽然我们持续致力于提升和加强安全措施，以保护您的个人信息免遭意外或破坏，但仍无法始终保证您的个人信息的百分之百安全。",
                    id:"12000002",
                    content:[]
                },{
                    text:"3. 请您理解，您使用产品或服务时所用的系统和通讯网络，或硬件设备等我们均无法控制，请您了解并注意保护您的个人信息安全。",
                    id:"12000003",
                    content:[]
                },{
                    text:"4. 请您注意，我们的网站、产品、应用软件和服务中可能含有第三方网站、产品或服务的链接。第三方收集的可能涉及您的个人信息，须遵守第三方关于个人信息的规定。我们希望你能了解这些第三方的隐私政策。我们会尽商业上的合理努力去要求这些第三方主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，亦不对这些主体的行为及后果承担任何责任。如果您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。",
                    id:"12000004",
                    content:[]
                },{
                    text:"5. 如同所有的企业都可能面临的问题一样，考虑到信息技术、网络空间本身的特点，在某些特殊情况下（例如黑客攻击、电信故障等），尽管我们采取了法律、法规等规定的必要的措施，但仍然存在个人信息泄露的风险。除非本隐私政策有其他规定，为了保护您的人身及财产安全，我们特别提醒您不要通过公共网络，向我们或任何以我们名义为您提供服务的第三方，提供您的账户密码、验证码、通信内容等信息。",
                    id:"12000005",
                    content:[]
                }
            ]
        },
        {
            title:"十、未成年人个人信息特别约定",
            id:"13000000",
            content:[
                {
                    text:"1、我们非常重视未成年人的个人信息保护，我们的网站、应用及相关产品或服务均不以未成年人为目标客户。我们不会故意收集未成年人的任何个人信息，除非您主动提供且因获取某些产品或服务必须提供某些未成年人的必要信息外（例如您提供的订单信息可能包括未成年人信息）。",
                    id:"13000001",
                    content:[]
                },{
                    text:"2、如果您是未成年人，请不要尝试在没有监护人或法定代理人陪同的情况下单独注册本网站，或不要以其它方式向我们提供任何您的个人信息。",
                    id:"13000002",
                    content:[]
                }
            ]
        },
        {
            title:"十一、联系我们",
            id:"14000000",
            content:[
                {
                    text:"如果您对本隐私政策有任何疑问、意见或建议，或您在使用我们产品或服务时，就您的个人信息的收集、使用、共享、访问、删除、更正等相关事宜有任何意见、建议、投诉或举报，或您在使用产品或服务时遇到哪些问题，请及时通过本网站客服联系我们，我们会在法律要求的期限内或合理期限内及时答复。",
                    id:"14000001",
                    content:[]
                }
            ]
        }, {
            title:"十二、适用法律与争议解决渠道",
            id:"15000000",
            content:[
                {
                    text:"1. 适用法律",
                    id:"14000001",
                    content:["本隐私政策的执行、解释及争议的解决均适用中华人民共和国法律，且不考虑任何冲突法。"]
                },{
                    text:"2. 争议解决",
                    id:"14000002",
                    content:["当您以适当的方式注册本网站或者开始、继续使用我们的产品或服务时，您应确认同意本隐私政策相关内容，您未就本隐私政策的相关内容明示表示拒绝的，视为您充分理解并同意本隐私政策。您理解并同意，在您明示拒绝前，我们依据本隐私政策收集和使用的个人信息仍然有效。"]
                },{
                    text:"3. 您和我们就本隐私政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，您同意将纠纷或争议提交至本隐私政策签订地杭州市上城区人民法院解决。",
                    id:"14000003",
                    content:[]
                }
            ]
        },
    ]
} 